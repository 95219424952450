<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{title}}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="query.weekCommencing"
                  :items="weeksCommencing"
                  item-text="week_commencing"
                  label="Week Commencing"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col>
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="querySortedUploadData()"
                >Run Query</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <div v-if="duplicateItemsArray.length > 0">
        <v-btn
          :loading="loading"
          :disabled="loading"
          small
          color="primary"
          class="mb-2 mt-2"
          @click="exportToExcel('xlsx')"
          type="button"
        >Excel</v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          small
          color="primary"
          class="ml-2 mb-2 mt-2"
          @click="exportToExcel('csv')"
          type="button"
        >CSV</v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          small
          color="primary"
          class="ml-2 mb-2 mt-2"
          @click="deleteSelectedResults()"
          type="button"
        >Delete Selected</v-btn>
        <div class="billingTable">
          <v-card :loading="loading">
            <v-card-title>
              Billing Data
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="selected"
              show-select
              item-key="id"
              :headers="headers"
              :items="duplicateItemsArray"
              :items-per-page="10"
              :search="search"
              id="data-table"
            ></v-data-table>
          </v-card>
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="5000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import SortedUpload from "@/services/SortedUpload.js";
import * as XLSX from 'xlsx'

export default {
  data() {
    return {
      // Title
      title: "Remove Sorted Duplicates",
      // Form
      valid: false,
      // Loading
      loading: false,
      // Snackbar
      snackbar: false,
      text: "",
      search: null,
      weekCommencing: null,
      weeksCommencing: [],
      query: { weekCommencing: null },
      data: [],
      duplicatedItems: [],
      duplicateItemsArray: [],
      headers: [
        { text: "Company Code", align: "left", value: "company_code" },
        { text: "Electio Reference", value: "electioReference" },
        { text: "Order Reference", value: "orderReference" },
        { text: "State", value: "state" },
        { text: "Carrier", value: "carrier_name" },
        { text: "Service", value: "service" },
        { text: "Shipped", value: "dateShipped" },
        { text: "Total", value: "totalCost" },
        { text: "No. of Packages", value: "numberOfPackages" },
/* REMOVED from 22/06/2020 - Change of Sorted Data
        { text: "Customer Name", value: "customerName" },
        { text: "Address", value: "addressLine1" },
        { text: "Postcode", value: "postcode" },
        { text: "Country", value: "country" },
        { text: "Tracking", value: "tracking" } */
      ],
      selected: []
    };
  },
  methods: {
    exportToExcel(type) {
      var wb2 = XLSX.utils.book_new();
      var ws2 = XLSX.utils.json_to_sheet(this.duplicateItemsArray);
      XLSX.utils.book_append_sheet(wb2, ws2, "Combined Detailed Data");
      XLSX.writeFile(wb2, "sortedDuplicatedData." + type);
    },
    sortFunc(array) {
      return array.slice().sort(function(a, b) {
        return a.name > b.name ? 1 : -1;
      });
    },
    async getWeeksCommencing() {
      // Use the BillingSnap to call the getBillingData() method
      return SortedUpload.getSortedWeeksCommencing()
        .then(
          (weeksCommencing => {
            this.$set(this, "weeksCommencing", weeksCommencing);
            return true;
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async deleteSelectedResults() {
      this.loading = true;
      let itemsToRemove = [];
      this.selected.forEach(sortedItem => {
        itemsToRemove.push(sortedItem.id);
      });
      SortedUpload.deleteSortedItems(itemsToRemove).then(response => {
        this.snackbar = true;
        this.text = `${response.successMessage[0]} - Reloading Data Now`;
        this.duplicateItemsArray = [];
        this.querySortedUploadData();
      });
    },
    async querySortedUploadData() {
      this.loading = true;
      // Use the BillingSnap to call the getBillingData() method
      SortedUpload.queryWeekSortedBillingData(this.query)
        .then(data => {
          if (data.length > 0) {
            this.data = data;
            this.getDuplicateItems();
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully returned Duplicate Items from the Sorted Data for`;
            for (const key in this.query) {
              if (this.query.hasOwnProperty(key)) {
                const element = this.query[key];
                if (element) {
                  this.text += ` ${key} - ${element} `;
                }
              }
            }
          }
        })
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    async getDuplicateItems() {
      SortedUpload.getDuplicatedSortedData(this.query)
        .then(duplicatedItems => {
          this.duplicatedItems = duplicatedItems;
          this.duplicatedItems.forEach(duplicate => {
            let tempOrderRef = duplicate.orderReference;
            let items = this.data.filter(
              item => item.orderReference == tempOrderRef
            );
            if (items) {
              items.forEach(element => {
                this.duplicateItemsArray.push(element);
              });
            }
          });
        })
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    async loadInitialData() {
      this.loading = true;
      let weeksCommencing = await this.getWeeksCommencing();
      if (weeksCommencing) {
        this.loading = false;
        this.text = `Select Sorted data by Week Commencing to view and remove any duplicated data.`;
        this.snackbar = true;
      }
    }
  },
  created() {
    this.loadInitialData();
  }
};
</script>

<style>
</style>